import React from 'react'
import CyberSecurityTraining from './CyberSecurityTraining'

const CyberSecurityInfo = () => {
  return (
    <section className='py-20 overflow-hidden'>
        <div className='flex justify-center flex-col md:flex-row items-center gap-8 text-center md:text-left container lg:items-start'>
            <div className='space-y-6 max-w-[560px] md:w-1/2'>
                <h1 className='text-[1.5rem] md:text-[2.3rem] lg:text-[3rem] font-semibold'>Protect Your Organization from Insider Threats and Cyber Attacks</h1>
                <div className='text-[1rem] md:text-[1.15rem] leading-[1.5]'>
                    <p>
                    In today's digital landscape, cyber threats are constantly evolving. Cybersecurity isn’t just a technical issue - it’s a people issue. A strong cybersecurity culture is essential to safeguarding your organization's sensitive information. JVEC Solutions offers an elite Corporate Cybersecurity Awareness Training program designed to transform your workforce into a proactive, vigilant line of defense against cyberattacks.
                    </p>
                </div>
            </div>

            <div className='md:h-[500px] rounded-3xl md:w-1/2'>
                <img src="/images/cybersecurity-awareness/cyber-security-info.jpg" alt="Testing Info" className='h-full w-full object-cover bg-right rounded-[2rem]' loading="lazy"/>
            </div>

        </div>

        <CyberSecurityTraining />
    </section>
  )
}

export default CyberSecurityInfo