import React from 'react'
import { Link } from 'react-router-dom'

const FortifySecurity = () => {
  return (
    <section className="py-20">
        <div className='container'>
            <div className='flex justify-center items-center mx-auto'>
                <div className='bg-[#111111] w-full flex justify-center items-center py-12 px-6 md:p-12'>
                    <div className='bg-[#282828] w-full flex flex-col justify-center items-center'>
                        <div className='text-center space-y-6 flex flex-col justify-center items-center p-6 md:p-12'>
                            <h2 className='text-[1.5rem] md:text-[2.3rem] lg:text-[3rem] font-semibold md:leading-[1.3]'>Ready to Fortify Your Security? Schedule Your Free Consultation.</h2>
                            <p className="text-[1rem] md:text-[1.15rem] leading-[1.5]">Let JVEC Solutions give you the peace of mind that comes with world-class cybersecurity.</p>
                            <Link
                                smooth={true}
                                duration={500}
                                to="/contact"
                                className="btn text-center font-bold py-2 w-full md:max-w-[14em] rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
                                >
                                Get A Free Consultation
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default FortifySecurity