import { useEffect } from "react";
import IndustryHero from "./components/IndustryHero";
import IndustryInfo from "./components/IndustryInfo";
import IndustryOffer from "./components/IndustryOffer";
import JvecAdvantages from "./components/IndustryJvecAdvantage";
import LegacyLeadership from "../../components/LegacyLeadership";
import ComplianceCertifications from "../../components/complianceCertifications/ComplianceCertification";
import TrustedClients from "../../components/trustedClients/TrustedClients";
import FortifySecurity from "./components/FortifySecurity";
import ContactUs from "../../components/ContactUs";

const IndustryPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <>
        <IndustryHero />
        <IndustryInfo />
        <IndustryOffer />
        <JvecAdvantages />
        <LegacyLeadership />
        <ComplianceCertifications />
        <TrustedClients />
        <FortifySecurity />
        <ContactUs />
    </>
  )
}

export default IndustryPage
