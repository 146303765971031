import { useEffect } from "react";
import ComplianceHero from "./components/ComplianceHero";
import ComplianceInfo from "./components/ComplianceInfo";
import CyberSecurityCompliance from "./components/CyberSecurityCompliance";
import LegacyLeadership from "../../components/LegacyLeadership";
import ComplianceExpertise from "./components/ComplianceExpertise";
import ComplianceCertifications from "../../components/complianceCertifications/ComplianceCertification";
import TrustedClients from "../../components/trustedClients/TrustedClients";
import ConsultatingCTA from "./components/ConsultingCTA";
import ContactUs from "../../components/ContactUs";

const ComplianceConsultingPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <>
      <ComplianceHero />
      <ComplianceInfo />
      <CyberSecurityCompliance />
      <LegacyLeadership />
      <ComplianceExpertise />
      <ComplianceCertifications />
      <TrustedClients />
      <ConsultatingCTA />
      <ContactUs />
    </>
  )
}

export default ComplianceConsultingPage