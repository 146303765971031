import VulnerabilityHero from "./components/VulnerabilityHero";
import { useEffect } from "react";
import VulnerabilityInfo from "./components/VulnerabilityInfo";
import AssessmentServices from "./components/AssessmentServices";
import LegacyLeadership from "../../components/LegacyLeadership";
import ComplianceCertifications from "../../components/complianceCertifications/ComplianceCertification";
import VulnerabilityApproach from "./components/VulnerabilityApproach";
import VulnerabilityCTA from "./components/VulnerabilityCTA";
import ContactUs from "../../components/ContactUs";

const VulnerabilityPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <>
        <VulnerabilityHero />
        <VulnerabilityInfo />
        <AssessmentServices />
        <LegacyLeadership />
        <ComplianceCertifications />
        <VulnerabilityApproach />
        <VulnerabilityCTA />
        <ContactUs />
    </>
  )
}

export default VulnerabilityPage