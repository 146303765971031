import React from 'react'

const IndustryInfo = () => {
  return (
    <section className='py-20 overflow-hidden'>
        <div className='flex justify-center flex-col md:flex-row items-center gap-8 text-center md:text-left container lg:items-start'>
            <div className='space-y-6 md:space-y-5 max-w-[560px] md:w-1/2'>
                <h1 className='text-[1.5rem] md:text-[2.3rem] lg:text-[3rem]font-semibold'>Helping You Overcome Business Challenges with Industry-Driven Insights</h1>
                <div className='space-y-4 text-[1rem] md:text-[1.15rem] leading-[1.5]'>
                    <p>
                        At JVEC Solutions, we believe that effectively addressing your business challenges begins with understanding the intricacies of your industry. With over 45 years of proven expertise in cybersecurity, our team is structured to bring sector-specific insights that help you anticipate and tackle critical issues unique to your industry.
                    </p>
                    
                    <p>
                    We empower businesses to transform, adapt, and thrive in an evolving digital landscape. Our services span comprehensive cybersecurity advisory, risk management, and compliance solutions, enabling you to operate securely, adapt to market demands, and safeguard critical assets.
                    </p>

                </div>
            </div>

            <div className='w-full md:h-[500px] md:w-1/2'>
                <img src="/images/industry/industry-info.jpg" alt="industry Info" className='h-full w-full object-cover bg-right rounded-[2rem]' loading="lazy"/>
            </div>

        </div>
    </section>
  )
}

export default IndustryInfo