

const LegacyLeadership = () => {
  return (
    <>
        <div className="container mx-auto space-y-6">
            <div className="bg-cover bg-top bg-legacy-leadership w-full min-h-[400px] lg:min-h-[550px] flex items-center flex-col justify-center text-center rounded-3xl">
                <div className="max-w-[860px] space-y-6 p-4">
                    <h2 className='text-[2.3rem] lg:text-[3rem]'>A Legacy of Security Leadership</h2>
                    <p className='text-base md:text-lg leading-[1.5]'>Our Cyber Executive team brings over 45 years of combined experience across key industries, including Financial Services, Information Technology, and Healthcare, with a global footprint spanning Africa, North America, Europe, and the Middle East.</p>
                </div>
            </div>
        </div>
    </>
  )
}

export default LegacyLeadership