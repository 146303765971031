export const WhyChooseJvectList = [
    {
      title: "Mitigate Risk",
      description: "Identify and address vulnerabilities to protect your organization from cyber threats."
    },
    {
      title: "Enhance Security Posture:",
      description: "Implement robust security controls and best practices."
    },
    {
      title: "Ensure Regulatory Compliance",
      description: "Stay ahead of evolving regulations and industry standards."
    },
    {
      title: "Protect Your Reputation",
      description: "Safeguard your brand and customer trust."
    },
    {
        title: "Optimize Business Operations:",
        description: "Streamline compliance processes and reduce operational costs."
      },
  ];

  export const securityTasks = [
    {
      title: "Gap Analysis",
      description: "Identifying and analyzing gaps in current security policies and compliance."
    },
    {
      title: "Policy and Procedure Development",
      description: "Creating and implementing comprehensive security policies and procedures."
    },
    {
      title: "Risk Assessments",
      description: "Assessing and prioritizing security risks."
    },
    {
      title: "Security Audits and Testing",
      description: "Conducting regular security assessments and penetration testing."
    },
    {
      title: "Incident Response Planning",
      description: "Developing and testing incident response plans."
    },
    {
      title: "Employee Training and Awareness",
      description: "Educating employees about security best practices."
    },
    {
      title: "Third-Party Risk Management",
      description: "Assessing the security practices of third-party vendors and suppliers."
    }
  ];