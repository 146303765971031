import { useEffect } from "react";
import ConsultingHero from "./components/ConsultingHero";
import ConsultingInfo from "./components/ConsultingInfo";
import IndustryJvecAdvantages from "./components/IndustryJvecAdvantages";
import LegacyLeadership from "../../components/LegacyLeadership";
import ComplianceCertifications from "../../components/complianceCertifications/ComplianceCertification";
import TrustedClients from "../../components/trustedClients/TrustedClients";
import ConsultatingCTA from "./components/ConsultingCTA";
import ContactUs from "../../components/ContactUs";

const CybersecurityConsultingPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <>
      <ConsultingHero />
      <ConsultingInfo />
      <IndustryJvecAdvantages />
      <LegacyLeadership />
      <ComplianceCertifications />
      <TrustedClients />
      <ConsultatingCTA />
      <ContactUs />
    </>
  )
}

export default CybersecurityConsultingPage