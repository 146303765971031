
export const ComplianceCertificates =[
    {
        image: "/images/compliance-certifications/pci-dss-complaint.svg",
        alt: "PCI DSS Complaint"
    },
    {
        image: "/images/compliance-certifications/iso-27001.svg",
        alt: "Information Security Management 27001"
    },
    {
        image: "/images/compliance-certifications/cyber-security-1-1.svg",
        alt: "Cyber Security Framework"
    },
    {
        image: "/images/compliance-certifications/gdpr.svg",
        alt: "General Data Protection Regulation"
    },
    {
        image: "/images/compliance-certifications/ndpc.svg",
        alt: "Nigeria Data Protection Commission"
    },
    {
        image: "/images/compliance-certifications/iso-22301.svg",
        alt: "Information Security for Standardization 22301"
    },
    {
        image: "/images/compliance-certifications/sabarnes-oxley-complaint.svg",
        alt: "Sarbanes oxley Complaint"
    },
    {
        image: "/images/compliance-certifications/togaf.svg",
        alt: "TOGAF"
    },
    {
        image: "/images/compliance-certifications/iso-20000.svg",
        alt: "Information Security for Standardization 20000"
    },
    {
        image: "/images/compliance-certifications/aicpa-soc-small.svg",
        alt: "AICPA SOC Small"
    },
    {
        image: "/images/compliance-certifications/aicpa-soc-large.svg",
        alt: "AICPA SOC Large"
    },
    {
        image: "/images/compliance-certifications/cobit-2019.svg",
        alt: "COBIT 2019"
    },
    {
        image: "/images/compliance-certifications/ssae18-soc-complaint.svg",
        alt: "SSAE 18 Complaint"
    },
    {
        image: "/images/compliance-certifications/pcaob.svg",
        alt: "Public Company Accounting Oversight Board"
    },
    {
        image: "/images/compliance-certifications/coso.svg",
        alt: "COSO"
    },
    {
        image: "/images/compliance-certifications/ssae16-type-ii.svg",
        alt: "SSAE 16 Type II"
    },
    {
        image: "/images/compliance-certifications/iaasb.svg",
        alt: "IAASB"
    },
    {
        image: "/images/compliance-certifications/isae-304.svg",
        alt: "Internation Standard on Assurance Engagement"
    }
]