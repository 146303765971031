import React from 'react'
import WhyChooseJvec from './WhyChooseJvec'

const PenetrationTestingInfo = () => {
  return (
    <section className='py-20'>
        <div className='flex justify-center flex-col md:flex-row items-center gap-6 text-center md:text-left container lg:items-start px-7'>
            <div className='space-y-6 max-w-[560px] md:w-1/2'>
                <h1 className='font-semibold text-[1.5rem] md:text-[2.3rem] lg:text-[3rem]'>What is Penetration Testing?</h1>
                <div className='space-y-4 text-[1rem] md:text-[1.15rem] leading-[1.5]'>
                    <p>
                        Penetration testing, or ethical hacking, is a proactive security measure where authorized experts simulate real-world cyberattacks on an organization’s systems, mimicking the methods and behaviors of malicious attackers. These simulated attacks reveal vulnerabilities in your network, applications, and data protection, providing critical insights that help you fortify your defenses.
                    </p>
                    <p>
                        With JVEC Solutions’ Penetration Testing Services, you gain the advantage of over <span className='font-bold'>45 years</span> of cybersecurity expertise, industry-leading strategies, and a commitment to security excellence.
                    </p>
                </div>
            </div>

            <div className='md:h-[500px] rounded-3xl md:w-1/2'>
                <img src="/images/pentest/cyber-attack.jpg" alt="Testing Info" className='h-full w-full object-cover bg-right rounded-[2rem]' loading="lazy"/>
            </div>

        </div>

        <WhyChooseJvec />
    </section>
  )
}

export default PenetrationTestingInfo