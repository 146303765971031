import { useEffect } from "react";
import AuditHero from "./components/AuditHero";
import AuditInfo from "./components/AuditInfo";
import JvecAuditServices from "./components/JvecAuditServices";
import LegacyLeadership from "../../components/LegacyLeadership";
import ComplianceCertifications from "../../components/complianceCertifications/ComplianceCertification";
import TrustedClients from "../../components/trustedClients/TrustedClients";
import AuditCTA from "./components/AuditCTA";
import ContactUs from "../../components/ContactUs";

const TechAuditAssurancePage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <>
    <AuditHero />
    <AuditInfo />
    <JvecAuditServices />
    <LegacyLeadership />
    <ComplianceCertifications />
    <TrustedClients />
    <AuditCTA />
    <ContactUs />
    </>
  )
}

export default TechAuditAssurancePage
