import brands from "./trusted-client-data"

const TrustedClients = () => {
  return (
    <section>
        <div className="container">
            <h2 className='text-center my-6 text-[1.5rem] md:text-[2.3rem] lg:text-[3rem] font-semibold'>They Trust Us, and so can You.</h2>

            <div className='grid justify-center h-fit grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-6 bg-gradient-to-r from-[#1e1c1c] via-[#222222] to-[#080808] p-6 rounded-md'>
                {brands.map((brand, index) => (
                <img key={index} src={brand.logo} alt={brand.name} className="w-full h-[60px] md:h-[79px] lg:h-[79px] flex justify-center items-center hover:opacity-85 hover:scale-105 transition duration-300 transform cursor-pointer" loading="lazy" />
                ))}
            </div>
        </div>
    </section>
  )
}

export default TrustedClients