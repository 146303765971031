export const cybersecurityHighlights = [
    {
      title: "Proven Expertise Across Diverse Industries and Regions",
      description: "Our cybersecurity expertise spans industries including Financial Services, Healthcare, and IT, with a global footprint that spans Africa, North America, Europe, and the Middle East. This gives us a comprehensive understanding of sector/region-specific challenges and best practices."
    },
    {
      title: "Customized, Compliance-Driven Solutions",
      description: "We align every recommendation with leading regulatory frameworks such as PCI DSS, ISO 27001, and GDPR, ensuring your organization not only meets compliance requirements but also builds a strong security foundation."
    },
    {
      title: "Strategic, Future-Focused Approach",
      description: "Our proactive, long-term strategies anticipate and adapt to emerging threats, ensuring your security remains resilient in a dynamic digital landscape."
    },
    {
      title: "Hands-On, Consultative Process",
      description: "With JVEC, you’re never just another client. We provide dedicated, one-on-one support, guiding you through each decision to ensure your cybersecurity aligns with your business objectives and maximizes ROI."
    }
  ];

export const ourCoreValues = [
    {
      title: "Risk Assessment and Vulnerability Management",
      description: "Identify and prioritize vulnerabilities within your systems to create a targeted, effective mitigation plan."
    },
    {
      title: "Compliance and Regulatory Guidance",
      description: "Simplify and achieve regulatory compliance with expert guidance that reduces risks and maintains audit readiness."
    },
    {
      title: "Incident Response Planning and Management",
      description: "Establish and refine response protocols, minimizing downtime and potential damage when threats arise."
    },
    {
      title: "Strategic Security Planning and Roadmapping",
      description: "Develop a customized, actionable roadmap to continually strengthen your organization’s cybersecurity posture."
    },
    {
      title: "Threat Intelligence and Monitoring ",
      description: "Gain real-time insights into emerging threats, ensuring proactive protection against evolving risks."
    },
  ];

  export const securityFeatures = [
    {
      title: "Expert-Driven Resilience",
      description: "Build a security strategy backed by JVEC’s decades of experience to withstand complex threats."
    },
    {
      title: "Operational Confidence",
      description: "Empower your organization to operate securely and confidently with a robust, transparent security framework."
    },
    {
      title: "Compliance Assurance",
      description: "Stay aligned with regulatory requirements effortlessly through JVEC’s compliance expertise."
    },
    {
      title: "Tailored, ROI-Focused Solutions",
      description: "See immediate value and long-term savings through cybersecurity strategies specifically designed to meet your business goals."
    }
  ];