import React from 'react';
import { cybersecurityTrainingStep } from './cybersecurity-awareness-data';

const CyberSecurityTraining = () => {
  return (
    <div className='mt-[80px]'>
      <div className='container'>
        <h1 className='text-[1.5rem] md:text-[2.3rem] lg:text-[3rem] font-semibold text-center md:max-w-[660px] mx-auto lg:max-w-[964px]'>
            Our Cybersecurity Awareness Training Services
        </h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 auto-rows-max gap-8 mt-[40px]">
          {cybersecurityTrainingStep.map((reason, index) => (
            <div className='bg-gradient-to-b flex-1 from-[#514D4D] to-[#080808] p-4 space-y-4 min-h-48 md:min-h-64 lg:min-h-48' key={reason.title} >
              <h2 className='text-sm md:text-base font-semibold'>{reason.title}</h2>
              <p className='text-sm md:text-base leading-relaxed'>{reason.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CyberSecurityTraining;
