import React, { useEffect } from "react";
import CardList from "../components/card/CardList";
import ContactUs from "../components/ContactUs";
import { Link } from "react-router-dom";
import '../styles/Home.css'

const HomeAboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <article className="homepage_about">
            <h2>About Us</h2>
        <div>
        <div className="container flex_wrap flex gap-10">
          <div className="text_col w-full md:w-1/2">
            <h5 data-aos='fade-down' data-aos-delay='200'>What We Represent</h5>

            <p className='first_text'data-aos='fade-up' data-aos-delay='300'>JVEC Solutions is a technology-focused firm specializing in crafting innovative business solutions tailored to empower enterprises across diverse scales in today’s dynamic technological landscape.
            </p> 
            <p className='second_text' data-aos='fade-up' data-aos-delay='400'> 
             Our core ethos, the Philosophy of Excellence, serves as our unwavering commitment to consistently provide unparalleled quality to our valued clients. We approach our relationships with clients with the utmost respect and prioritize their needs. This commitment drives our rigorous dedication to every project, ensuring our clients get maximum value for money.
            </p>

            <Link data-aos='fade-right' data-aos-delay="200" to="/about" className="btn_arr">Learn More &rarr;</Link>
          </div>
          <div className="image_col w-full md:w-1/2">
            <div className="img_wrap">
              <img src="/images/about_us/about_us2.jpg" alt="about-jvec"
              className="w-[40%] lg:w-[100%] rounded_border" />
            </div>
          </div>

        </div>
        </div>

        <CardList/>

        {/* certified by dun and bradstreet */}
        <div>
          <div className='container max-w-[760px]'>
              <h1 className='text-2xl pb-5 md:pb-10 md:text-4xl lg:text-5xl text-center font-semibold'>Certified by dun & bradstreet - USA</h1>

              <div className='bg-white rounded-md w-full mx-auto p-4'>
                  <img
                      src="/images/dun-bradstreet.svg"
                      alt="dun and bradstreet"
                      loading="lazy"
                      className='mx-auto'
                  />
              </div>
          </div>
        </div>

        <section id='contact-us-comp'>
        <ContactUs/>
        </section>
      </article>    
  );
};

export default HomeAboutUs;