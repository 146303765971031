import React from 'react'
import { securityFeatures } from './consulting-data'
import Accordion from '../../../components/accordion/accordion'
  

const IndustryJvecAdvantages = () => {
  return (
    <section className='py-20 overflow-hidden'>
        <div className='overflow-hidden container space-y-6'>
            <h1 className='text-[1.5rem] md:text-[2.3rem] lg:text-[3rem] font-semibold mb-8 text-center md:text-left lg:max-w-[500px]'>The JVEC Advantage</h1>

            <div className='md:flex justify-start gap-3 mb-[40px]'>
              <div className='space-y-6 text-[1rem] md:text-[1.15rem] leading-[1.5] md:w-1/2'>
                <p className='text-center md:text-left'>
                  As a trusted leader in cybersecurity consulting, JVEC Solutions leverages decades of expertise to deliver tailored advisory services that align security strategies with business objectives. Our client-focused approach ensures proactive, compliant, and resilient solutions, empowering your organization to stay ahead of evolving cyber risks.
                </p>
                <Accordion accordionData={securityFeatures} />
              </div>
              <div className='w-full mt-[40px] md:mt-0 max-w-[800px] h-[550px] md:w-1/2'>
                <img src="/images/cybersecurity-consulting/jvec-advantage.jpg" alt="pc protection" className='h-full w-full object-cover md:rounded-md' loading="lazy" />
              </div>
            </div>
        </div>
    </section>
  )
}

export default IndustryJvecAdvantages