import React from 'react';
import { WhyChooseJvectList } from './cybersecurity-compliance-data';

const WhyChooseJvecCompliance = () => {
  return (
    <div className='mt-[80px]'>
      <div className='container'>
        <div className="space-y-8 md:max-w-[660px] mx-auto lg:max-w-[964px] text-center">
            <h1 className='text-[1.5rem] md:text-[2.3rem] lg:text-[3rem] font-semibold'>
                Why Choose JVEC Solutions?
            </h1>
            <p className='text-[1rem] md:text-[1.15rem] leading-[1.5]'>With over 45 years of industry experience, JVEC Solutions is your trusted partner in achieving and maintaining cybersecurity compliance. Our comprehensive compliance services are designed to help you:
            </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 auto-rows-max gap-8 mt-10">
          {WhyChooseJvectList.map((reason, index) => (
            <div className='bg-gradient-to-b flex-1 from-[#514D4D] to-[#080808] p-4 space-y-4 min-h-48 md:min-h-64 lg:min-h-48' key={reason.title} >
              <h2 className='text-sm md:text-base font-semibold'>{reason.title}</h2>
              <p className='text-sm md:text-base leading-relaxed'>{reason.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseJvecCompliance;
