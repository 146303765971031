import { trainingModules } from "./cybersecurity-awareness-data";


const CyberSecurityAwareness = () => {
    return (
        <section className="pt-10 pb-20">

            <div className="container">
                <h1 className='text-[1.5rem] md:text-[2.3rem] lg:text-[3rem] font-semibold mb-8 text-center md:text-left lg:max-w-[700px]'> Why Choose JVEC Solutions for Cybersecurity Awareness Training?</h1>

                <div className="lg:flex gap-8 lg:space-y-0 space-y-20">
                    <div className='w-full md:h-[500px] lg:w-1/2'>
                        <picture>
                            <img src="/images/cybersecurity-awareness/cyber-security-awareness-training.jpg" alt="cyber security awareness" className='h-full w-full object-cover bg-right rounded-3xl' loading="lazy"/>
                        </picture>
                    </div>

                    <div className="relative space-y-12 max-h-[80vh] overflow-y-scroll lg:w-1/2 scroll-smooth lg:px-4">
                        {trainingModules.map((module, index) => (
                            <div key={index} className="space-y-6">
                                <h1 className='font-bold text-[1.5rem] md:text-[2.3rem] lg:text-[2.5rem] leading-[1.3]'>
                                    {module.title}
                                </h1>
                                <p>
                                    {module.description}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

        </section>
    );
};

export default CyberSecurityAwareness;
