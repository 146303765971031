import React from 'react'
import WhyChooseConsulting from './WhyChooseConsulting'

const ConsultingInfo = () => {
  return (
    <section className='py-20 overflow-hidden'>
        <div className='flex justify-center flex-col md:flex-row items-center gap-8 text-center md:text-left container lg:items-start'>
            <div className='space-y-6 max-w-[560px] md:w-1/2'>
                <h1 className='text-[1.5rem] md:text-[2.3rem] lg:text-[3rem] font-semibold'>Protect Your Business with JVEC’s Cybersecurity Advisory Services</h1>
                <div className='text-[1rem] md:text-[1.15rem] leading-[1.5]'>
                    <p>
                        In today’s ever-evolving threat landscape, organizations face constant challenges in protecting sensitive data, ensuring regulatory compliance, and building operational resilience. With over 45 years of industry leadership, JVEC Solutions stands as a trusted cybersecurity partner, committed to delivering tailored, strategic guidance that empowers organizations to stay ahead of threats
                    </p>
                </div>
            </div>

            <div className='w-full md:h-[500px] md:w-1/2'>
                <img src="/images/cybersecurity-consulting/consulting-info.jpg" alt="consulting" className='h-full w-full object-cover bg-right rounded-xl' loading="lazy"/>
            </div>

        </div>

        <WhyChooseConsulting />
    </section>
  )
}

export default ConsultingInfo