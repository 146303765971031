import React from 'react'
import { ComplianceCertificates } from './compliance-data'

const ComplianceCertifications = () => {
  return (
    <section className='py-20'>
        <div className='container space-y-6 flex items-center flex-col'>
          <div className='space-y-8 w-full md:max-w-[660px] mx-auto lg:max-w-[964px] text-center'>
              <h2 className='font-semibold text-[1.5rem] md:text-[2.3rem] lg:text-[3rem]'>Compliance Certifications And Standards We Specialize In
              </h2>
              <p className='text-base md:text-lg leading-[1.5]'>Elevate Your Organization's Security Posture with Our Compliance/Standards Consulting Services.
              </p>
          </div>

          <div className='w-full bg-white rounded-lg shadow-md grid grid-cols-3 md:grid-cols-6 justify-center items-center gap-4 p-6'>
            {ComplianceCertificates.map((certifications, index) => (
                <div key={certifications.alt} className='flex justify-center items-center'>
                  <img src={certifications.image} alt={certifications.alt} loading="lazy" className='hover:opacity-85 hover:scale-105 transition duration-300 transform cursor-pointer'/>
              </div>
            ))}
          </div>
      </div>
    </section>
  )
}

export default ComplianceCertifications