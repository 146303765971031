import ComplianceCertifications from "../../components/complianceCertifications/ComplianceCertification";
import JvecApproach from "./components/JvecApproach";
import PenetrationTestingInfo from "./components/PenetrationTestingInfo"
import PenetrationTestingServices from "./components/PenetrationTestingServices";
import PentestHero from "./components/PentestHero"
import PentestCTA from "./components/PentestCTA";
import TrustedClients from "../../components/trustedClients/TrustedClients";
import { useEffect } from "react";
import ContactUs from "../../components/ContactUs";

const PentestPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <>
        <PentestHero />
        <PenetrationTestingInfo />
        <PenetrationTestingServices />
        <ComplianceCertifications />
        <JvecApproach />
        <TrustedClients />
        <PentestCTA />
        <ContactUs />
    </>
  )
}

export default PentestPage
