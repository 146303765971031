import React from 'react'
import Accordion from '../../../components/accordion/accordion';
import { AuditSteps } from './tech-audit-data';

const JvecAuditServices = () => {
  return (
    <section className='pb-20'>
        <div className='overflow-hidden container'>
            <h1 className='text-[1.5rem] md:text-[2.3rem] lg:text-[3rem] font-semibold mb-8 text-center md:text-left lg:max-w-[700px]'>Our Technology Audit and Assurance Services:</h1>

            <div className='md:flex justify-start space-y-8 md:space-y-0 gap-6 mb-[40px]'>
              <div className='space-y-6 text-[1rem] md:text-[1.15rem] leading-[1.5] md:w-1/2'>
                <p className='text-center md:text-left'>
                  As a trusted leader in technology audit and assurance, JVEC Solutions leverages decades of expertise to evaluate and optimize your IT systems. Our client-focused approach ensures thorough assessments, compliance alignment, and enhanced operational resilience, empowering your organization to thrive in an evolving digital landscape.
                </p>
                <Accordion accordionData={AuditSteps} />
              </div>
              <div className='md:h-[500px] rounded-3xl md:w-1/2'>
                <img src="/images/tech-audit-assurance/audit-services.jpg" alt="audit-Info" className='h-full w-full bg-cover bg-right rounded-[2rem]' loading="lazy"/>
            </div>
            </div>
        </div>
    </section>
  )
}

export default JvecAuditServices