import React, { useEffect } from 'react';
import BrandCarousel from '../components/brandCarousel/BrandCarousel';
import '../styles/Home.css'
import VerticalCarousel from '../components/verticalCarousel/VerticalCarousel';
import HeroPage from './Hero';
import VideoPlayer from '../components/videoPlayer/VideoPlayer';
import Section from '../components/section/Section';
import TestimonialCarousel from '../components/testimonialCarousel/TestimonialCarousel';
import testimonials from '../components/testimonialCarousel/testimonials';
import Aos from 'aos';
import 'aos/dist/aos.css'
import HomeAboutUs from './HomeAboutUs';
import { Link } from 'react-router-dom';
import LegacyLeadership from '../components/LegacyLeadership';
import ComplianceCertifications from '../components/complianceCertifications/ComplianceCertification';
import TrustedClients from '../components/trustedClients/TrustedClients';



const Home = () => {

  useEffect(()=>{
    Aos.init({duration: 1500})
    window.scrollTo(0, 0);
  
  }, []);

  const features = [
    {
      icon: '/images/home/icons/percentage-square.png',
      iconActive: '/images/home/icons/percentage-square-blue.png',
      title: '100% Client Satisfaction',
      description: 'Our unwavering commitment to excellence empowers us to deliver impeccable services, consistently exceeding expectations within every deadline.'
    },
    {
      icon: '/images/home/icons/award.png',
      iconActive: '/images/home/icons/award-blue.png',
      title: '7+ Years of Excellence',
      description: 'Distinguished by our relentless pursuit of perfection, we seamlessly provide unparalleled services that redefine industry standards.'
    },
    {
      icon: '/images/home/icons/star.png',
      iconActive: '/images/home/icons/star-blue.png',
      title: '5-Star Customer Support',
      description: 'At the heart of our company beats a dedication to unmatched customer support, where we stand by our clients at every turn, offering unwavering assistance throughout their journey with us.'
    },
  ];

  const offeringServices = [
    {
      label: "Technology Audit and Assurance",
      link:"/tech-audit",
    },
    {
      label: "Compliance Consulting",
      link:"/compliance-consulting",
    },
    {
      label: "Vulnerability Assessment",
      link:"/vulnerability-assessment",
    },
    {
      label: "Penetration Testing",
      link:"/pentest",
    },
    {
      label: "Corporate Cybersecurity Awareness Training",
      link:"/cybersecurity-awareness",
    },
    {
      label: "Cybersecurity Advisory and Consulting",
      link:"/cybersecurity-consulting",
    },
    {
      label: "Mobile Application Development",
      link:"/services/mobile",
    },
    {
      label: "Enterprise Application Software Development",
      link:"/services/software",
    },
    {
      label: "Custom Software Development",
      link:"/services/software",
    },
    {
      label: "Website Development",
      link:"/services/website",
    },
    {
      label: "E-Commerce Solutions",
      link:"/services/software",
    },
    {
      label: "Corporate Training",
      link:"/services/corporate",
    },
  ];
  
  return (
    <div className="homepage">
      <HeroPage />
      <section className="brand">
        <BrandCarousel />
      </section>

      <main id="main-content">
        <div className="flex_wrapper flex_start container px-7">
          <div className="text_col">
            <h2 data-aos='fade-up' data-aos-delay='100' >Why we are your Trusted ICT Consultant.</h2>
            <p data-aos='fade-up' data-aos-delay='200'>We unleash the potential of Technology for your Business</p>
            <Link
              to="services"
              smooth={true}
              duration={500}
              className="btn btn_lg"
              data-aos='fade-up' 
              data-aos-delay='300'
              data-aos-anchor-placement="top-bottom" 
              data-aos-easing="ease-out-cubic"
            >
              Learn more
            </Link>
            <div>
        </div>
          </div>
          <div className="vertical_slide">
            <VerticalCarousel features={features} />
          </div>
        </div>
      </main>
      <div className="middle_section">
        <div className="container flex_wrapper flex_start">
          <div className="video_wrapper">
            <VideoPlayer  />

          </div>
          <div className="text_col">
            <h2 data-aos='fade-down' data-aos-delay='100' data-aos-offset="100">JVEC — Pioneering Excellence in ICT Solutions</h2>
            <p data-aos='fade-up' data-aos-delay='100'>
            Excellence isn’t a goal, it’s our DNA; our relentless pursuit of excellence is the driving force behind our success. Excellence is our legacy, and we are proud to carry it forward. Watch the video to see how we are innovating to keep businesses on top of their games.
            </p>
            <Link 
              data-aos='fade-in' 
              data-aos-delay='100'
              smooth={true}
              duration={500}
              data-aos-anchor-placement="top-bottom" 
              data-aos-easing="ease-out-cubic"
              to="services" className="btn btn_lg ">
                Get Started
            </Link>
          </div>
        </div>
      </div>

      <section className="py-20">
        <div className="container mx-auto space-y-8 text-center" data-aos='fade-down' data-aos-delay='100' data-aos-offset="100">
          <h2 className='font-semibold text-2xl md:text-4xl lg:text-5xl'>Our Offerings</h2>
          <p className='text-base md:text-lg leading-[1.5] lg:max-w-[964px] mx-auto'>
          Leverage JVEC Solutions' cutting-edge expertise to transform your business. Our tailored software and cybersecurity solutions drive efficiency, security, and growth. We empower businesses to thrive in the digital age.
          </p>

            <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5' data-aos='fade-up' data-aos-delay='100'>
              {offeringServices.map((service, index) => (
                  <Link to={service.link} key={index} className='bg-[#222222] cursor-pointer rounded-md border border-[#726E6E] hover:border-[#3d3b3b] min-h-[80px] flex items-center justify-center text-center p-4'>
                      <span>{service.label}</span>
                  </Link>
              ))}
          </div>
        <Link 
          data-aos='fade-in' 
          data-aos-delay='100'
          smooth={true}
          duration={500}
          data-aos-anchor-placement="top-bottom"
          data-aos-easing="ease-out-cubic"
          to="services" className="btn btn_lg">
            View More
        </Link>
      </div>
      </section>

      <section data-aos='fade-down' data-aos-delay='100' data-aos-offset="100">
        <LegacyLeadership />
      </section>

      <ComplianceCertifications/>

      <div className="pb-10 md:pb-20 ">
        <TrustedClients />
      </div>

      <Section/>

      <div className="testimonial_home">
        <h3 data-aos='fade-down' data-aos-delay='100' data-aos-offset="100" className='text-60 '>Building Trust</h3>
        <p data-aos='fade-up' data-aos-delay='200' className="text-center text-30 mb-10">Trust earned and preserved</p>
        <div className="container">
          <TestimonialCarousel testimonials={testimonials} />
        </div>
      </div>
      <HomeAboutUs />
    </div>
  );
};

export default Home;
