import { Link } from "react-router-dom";

const VulnerabilityHero = () => {

  return (
    <section 
      className='min-h-screen relative overflow-hidden flex items-center justify-center bg-cyber-security-bg bg-cover bg-center'
    >
      <div className=''>
        <div className='text-center container relative z-20 max-w-[1100px]'>
          <div className='space-y-6'>
            <h1
              className="font-bold text-[clamp(2em,6vw,3.5em)]"
            >
              Fortify Your Digital Shield.
            </h1>

            <p className="text-[clamp(0.8em,2vw,1.5em)]">
              Discover and Eliminate Weaknesses.
            </p>
          </div>
          
          <div className="btn-holder mt-10">
            <Link
              smooth={true}
              duration={500}
              to="/contact"
              className="btn text-center font-bold py-2 w-full md:max-w-[12em] rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
            >
              Get A Free Consultation
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VulnerabilityHero;
