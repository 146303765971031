import React from 'react'
import WhyChooseAssessment from './WhyChooseAssessment'

const VulnerabilityInfo = () => {
  return (
    <section className='py-20 overflow-hidden'>
        <div className='flex justify-center flex-col md:flex-row items-center gap-8 text-center md:text-left container lg:items-start'>
            <div className='space-y-6 md:space-y-5 max-w-[560px] md:w-1/2'>
                <h1 className='font-semibold text-[1.5rem] md:text-[2.3rem] lg:text-[3rem]'>What is Vulnerability Assessment?</h1>
                <div className='space-y-4 text-[1rem] md:text-[1.15rem] leading-[1.5]'>
                    <p>
                    A vulnerability assessment is a systematic process of identifying, classifying, and prioritizing potential weaknesses in your IT infrastructure. It's like a thorough security checkup for your digital assets.
                    </p>
                    
                    <div className="space-y-2">
                        <h2 className="text-2xl font-semibold">Why is it Important?</h2>
                        <ul className="list-disc text-lg space-y-2 pl-5 text-left">
                            <li>Proactive Security: Identify and address vulnerabilities before they can be exploited by cybercriminals</li>
                            <li>Reduced Risk of Data Breaches: Protect sensitive information and maintain customer trust.
                            </li>
                            <li>Regulatory Compliance: Ensure adherence to industry standards and regulations.
                            </li>
                            <li>Enhanced Business Reputation: Demonstrate a strong commitment to cybersecurity.
                            </li>
                        </ul>
                    </div>

                </div>
            </div>

            <div className='w-full md:h-[500px] md:w-1/2'>
                <img src="/images/vulnerability-assessment/assessment-info.jpg"  alt="vulenrability" className='h-full w-full object-cover bg-right rounded-xl' loading="lazy"/>
            </div>

        </div>

        <WhyChooseAssessment />
    </section>
  )
}

export default VulnerabilityInfo