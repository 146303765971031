import { useEffect } from "react";
import CyberSecurityHero from "./components/CyberSecurityHero";
import CyberSecurityInfo from "./components/CyberSecurityInfo";
import CyberSecurityAwareness from "./components/CyberSecurityAwareness";
import LegacyLeadership from "../../components/LegacyLeadership";
import ComplianceCertifications from "../../components/complianceCertifications/ComplianceCertification";
import TrustedClients from "../../components/trustedClients/TrustedClients";
import CyberSecurityCTA from "./components/CyberSecurityCTA";
import ContactUs from "../../components/ContactUs";

const CybersecurityAwarenessPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <>
        <CyberSecurityHero />
        <CyberSecurityInfo />
        <CyberSecurityAwareness />
        <LegacyLeadership />
        <ComplianceCertifications />
        <TrustedClients />
        <CyberSecurityCTA />
        <ContactUs />
    </>
  )
}

export default CybersecurityAwarenessPage