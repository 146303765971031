import React, { useEffect } from 'react';
import '../styles/Home.css';
import "../components/headerFooter/HeaderFooter.css";
import Typewriter from "typewriter-effect";
import Aos from 'aos';
import "aos/dist/aos.css";
import { Link } from 'react-router-dom';


const HeroPage = () => {

  useEffect(()=>{
  Aos.init({duration: 2000})
  
  },[])
  return (
    <header className="homepage_hero inner_padding grid_wrapper">
      <h1 className='hero_head hero_gap px-9 mb-6'>Software &amp; Cybersecurity Solutions for Forward-thinking Businesses.</h1>
      <div className="px-9">
        <Typewriter
          onInit={(typewriter) => {
            typewriter
            .typeString("<p>Fortifying Businesses with Unmatched Cybersecurity Expertise.</p>")
            .pauseFor(1500)
            .deleteAll()
            .typeString("<p>Industry-Leading Cybersecurity Solutions.</p>")
            .pauseFor(1500)
            .deleteAll()
            .typeString("<p>Empowering Your Business with Cutting-Edge Software.</p>")
            .pauseFor(1500)
            .deleteAll()
            .typeString("<p>Driving Digital Transformation, One Solution at a Time.</p>")
            .pauseFor(1500)
            .deleteAll()
            .start();
          }}
          options={{
            autoStart: true,
            loop: true,
            delay: 25,
            deleteSpeed: 5
          }}
        />
      </div>

      <Link
        to="services"
        smooth={true}
        duration={500}
        className="btn btn_lg"
        data-aos='fade-up' 
        data-aos-delay='100'
        data-aos-anchor-placement="top-bottom" 
      >
        <div className="button-text text-white text-sm font-bold font-['Noto Sans'] tracking-tight">Get Started</div>
      </Link>
    </header>
  );
};

export default HeroPage;
