import React from 'react';
import { WhyChooseJvecListOne, WhyChooseJvecListTwo } from './penetrating-testing-data';

const WhyChooseJvec = () => {
  return (
    <div className='mt-[80px]'>
      <div className='container'>
        <h1 className='font-semibold mb-6 text-[1.5rem] md:text-[2.3rem] lg:text-[3rem] text-center md:max-w-[660px] mx-auto lg:max-w-[964px]'>
          Why Choose JVEC Solutions for Penetration Testing?
        </h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 auto-rows-max gap-6 mt-[20px]">
          {WhyChooseJvecListOne.map((reason, index) => (
            <div className='text-lg' key={reason.title}>
              {index === 0 ? (
                <div className='flex flex-1 justify-center text-[clamp(1.2em,6vw,2em)] leading-[1.5] text-center md:text-left'>
                  <h3><span className='mr-1'>1. </span>{reason.description}</h3>
                </div>
              ) : (
                <div className='bg-gradient-to-b flex-1 from-[#514D4D] to-[#080808] p-4 space-y-4 min-h-48 md:min-h-64 lg:min-h-48'>
                  <h2 className='text-sm md:text-base font-semibold'>{reason.title}</h2>
                  <p className='text-sm md:text-base leading-relaxed'>{reason.description}</p>
                </div>
              )}
            </div>
          ))}
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-[60px] auto-rows-max">
          {WhyChooseJvecListTwo.map((reason, index) => (
            <div 
              className={`text-lg ${index === 1 ? 'order-0 md:order-1' : 'order-1 md:order-0'}`} 
              key={reason.title}
            >
              {index === 1 ? (
                <div className='flex flex-1 text-[clamp(1.2em,6vw,2em)] leading-[1.5] text-center md:text-left'>
                  <h2>2. {reason.description}</h2>
                </div>
              ) : (
                <div className='bg-gradient-to-b flex-1 from-[#514D4D] to-[#080808] p-4 space-y-4 min-h-48 md:min-h-64 lg:min-h-48'>
                  <h2 className='text-sm md:text-base font-semibold'>{reason.title}</h2>
                  <p className='text-sm md:text-base leading-relaxed'>{reason.description}</p>
                </div>
              )}
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};

export default WhyChooseJvec;
