export const WhyChooseJvecListOne = [
    {
        title: "1.",
        description: "Unparalleled Benefits For Robust Cybersecurity:"
    },
    {
        title: "Preemptive Defense Against Cyber Threats",
        description: "Protect your most valuable data and systems by identifying and eliminating weaknesses before hackers exploit them. Our penetration tests expose potential entry points across your network, applications, and cloud infrastructure, keeping you one step ahead of attackers."
    },
    {
        title: "Comprehensive Compliance Support",
        description: " JVEC’s testing protocols align with industry compliance standards like GDPR, PCI DSS, and ISO 27001, ensuring you meet regulatory requirements while safeguarding data integrity and confidentiality."
    },
    {
        title: "Actionable, Insightful Reporting",
        description: " Beyond uncovering vulnerabilities, our detailed, accessible reports prioritize risks and outline specific, actionable remediation steps, empowering your team to strengthen security with clarity and confidence."
    },
]

export const WhyChooseJvecListTwo = [
    {
        title: "A Legacy of Security Leadership",
        description: "Our Cyber Executive team brings over 45 years of combined experience across key industries, including Financial Services, Information Technology, and Healthcare, with a global footprint spanning Africa, North America, Europe, and the Middle East"
    },
    {
        title: "2.",
        description: "Our Core Values: Trusted Expertise and Client Commitment"
    },
    {
        title: "Tailored, Client-Centric Approach",
        description: "Recognizing that each organization’s security landscape is unique, JVEC customizes testing strategies to address specific threats relevant to your industry, business model, and technological environment. We see our clients as partners in security, working closely with you at every step to enhance resilience."
    },
    {
        title: "Transparent Collaboration and Continuous Support",
        description: "Security doesn’t end with a test. We offer ongoing guidance and hands-on support, ensuring vulnerabilities are effectively mitigated and that your security infrastructure evolves to meet emerging threats."
    },
]

export const JvecApproachPenetrationAccordion = [
    {
        title: "Alignment with Business Goal",
        description: "JVEC ensures every technical recommendation aligns seamlessly with your business objectives, maximizing return on investment by focusing on solutions that drive value and support growth."
    },
    {
        title: "Proactive Defense",
        description: "We prioritize Planning, Preparation, and Prevention, moving beyond reactionary troubleshooting to provide continuous, proactive security that strengthens your defense before an incident can occur."
    },
    {
        title: "No-Surprise, Transparent Process",
        description: "Our “No Surprise” policy ensures clarity and transparency from planning through execution, giving you confidence and control over your security strategy at every stage."
    },
    {
        title: "Operational Continuity",
        description: "With efficient and thorough penetration tests, we help you maintain essential operations, minimizing any interruptions to your day-to-day activities while protecting your critical assets."
    },
    {
        title: "Personalized Consultative Experience",
        description: "We value personalized service, working closely with your team to ensure you’re never “lost in the shuffle.” Our dedicated experts guide you through each step of the process, making complex security strategies accessible and actionable."
    },
]