import React from 'react'
import Accordion from '../../../components/accordion/accordion'
import { proactiveDefenseFeatures } from './vulnerability-data'
import TrustedClients from '../../../components/trustedClients/TrustedClients'
  

const VulnerabilityApproach = () => {
  return (
    <section className='py-10'>
        <div className='overflow-hidden container'>
            <h1 className='text-[1.5rem] md:text-[2.3rem] lg:text-[3rem] font-semibold mb-8 text-center md:text-left lg:max-w-[700px]'>The JVEC Approach to Vulnerability Assessment</h1>

            <div className='md:flex justify-start gap-6 mb-[80px]'>
              <div className='space-y-6 text-[1rem] md:text-[1.15rem] leading-[1.5] md:w-1/2'>
                <p className='text-center md:text-left'>
                As a trusted leader in Vulnerability Assessment, JVEC Solutions combines decades of cybersecurity expertise with a client-centric, consultative approach to deliver unparalleled security assessments. Our commitment to proactive, customized Vulnerability Assessment ensures your organization is safeguarded against evolving cyber threats while minimizing business disruption.
                </p>
                <Accordion accordionData={proactiveDefenseFeatures} />
              </div>

              <div className='w-full mt-[40px] md:mt-0 max-w-[800px] h-[550px] md:w-1/2 '>
                <img src="/images/vulnerability-assessment/vulnerability-approach.jpg"alt="approach" className='h-full w-full object-cover md:rounded-md' loading="lazy" />
              </div>
            </div>

            <TrustedClients />
        </div>
    </section>
  )
}

export default VulnerabilityApproach