import React from 'react'
import Accordion from '../../../components/accordion/accordion'
import { securityAssessmentSteps } from './vulnerability-data'
  

const AssessmentServices = () => {
  return (
    <section className='py-20'>
        <div className='overflow-hidden container'>
            <h1 className='text-[1.5rem] md:text-[2.3rem] lg:text-[3rem] font-semibold mb-8 text-center md:text-left lg:max-w-[700px]'>Our Vulnerability Assessment Services</h1>

            <div className='md:flex justify-start gap-3 mb-[40px]'>
              <div className='space-y-6 md:w-1/2'>
                <p className='text-center md:text-left'>
                  As a trusted authority in vulnerability assessment, JVEC Solutions leverages decades of cybersecurity expertise to identify and prioritize security weaknesses across your systems. Our proactive, tailored approach ensures your organization is equipped to address vulnerabilities effectively, enhancing resilience against evolving cyber threats while maintaining operational continuity.
                </p>
                <Accordion accordionData={securityAssessmentSteps} />
              </div>

              <div className='w-full mt-[40px] md:mt-0 max-w-[800px] h-[550px] md:w-1/2'>
                <img src="/images/vulnerability-assessment/assessment-services.jpg"alt="assessment services" className='h-full w-full object-cover md:rounded-md' loading="lazy" />
              </div>
            </div>
        </div>
    </section>
  )
}

export default AssessmentServices