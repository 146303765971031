import { Link } from "react-router-dom"
const ConsultatingCTA = () => {
  return (
    <section className='relative py-20 overflow-hidden space-y-20'>
        <div className='flex md:flex-row-reverse flex-col justify-center gap-8 container'>
            <div className='space-y-8 text-center md:text-right md:w-1/2'>
                <h1 className='font-semibold text-[1.5rem] md:text-[2.3rem] lg:text-[3rem]'>Strengthen Your Organization with JVEC’s Advisory and Consulting Services</h1>

                <p className="text-[1rem] md:text-[1.15rem]">Backed by over 45 years of expertise, JVEC Solutions offers advisory and consulting services that do more than protect—they build a proactive, resilient defense tailored to your compliance requirements. Stay ahead of evolving cyber threats with solutions that fortify your organization’s security posture and secure its future.</p>
            </div>
            <div className='w-full md:w-1/2 md:h-[494px]'>
                <img src="/images/pentest/cta-img.jpg" alt="cta" className='h-full w-full object-cover md:rounded-md' loading="lazy" />
            </div>
        </div>

        <div className='container pt-20'>
            <div className='flex justify-center items-center mx-auto'>
                <div className='bg-[#111111] w-full flex justify-center items-center py-12 px-6 md:p-12'>
                    <div className='bg-[#282828] w-full flex flex-col justify-center items-center'>
                        <div className='text-center space-y-6 flex flex-col justify-center items-center p-6 md:p-12'>
                            <h2 className='text-[1.5rem] md:text-[2.3rem] lg:text-[3rem] font-semibold md:leading-[1.3]'>Ready to Fortify Your Security? Schedule Your Free Consultation</h2>
                            <p className="text-[1rem] md:text-[1.15rem] leading-[1.5]">Let JVEC Solutions give you the peace of mind that comes with world-class cybersecurity.</p>
                            <Link
                                smooth={true}
                                duration={500}
                                to="/contact"
                                className="btn text-center font-bold py-2 w-full md:max-w-[14em] rounded-md focus:bg-primary-color-500 duration-150 active:bg-blue-800"
                                >
                                Get A Free Consultation
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default ConsultatingCTA


