import React from 'react'
import { JvecApproachPenetrationAccordion } from './penetrating-testing-data';
import Accordion from '../../../components/accordion/accordion';

const JvecApproach = () => {
  return (
    <section>
        <div className='overflow-hidden container'>
            <h1 className='font-semibold text-[1.5rem] md:text-[2.3rem] lg:text-[3rem] mb-8 text-center md:text-left lg:max-w-[700px]'>The JVEC Approach to Penetration Testing</h1>

            <div className='md:flex justify-start gap-3 mb-[56px]'>
              <div className='space-y-8 md:w-1/2'>
                <p className='text-center md:text-left text-[1rem] md:text-[1.15rem]'>
                    As a trusted leader in penetration testing, JVEC Solutions combines decades of cybersecurity expertise with a client-centric, consultative approach to deliver unparalleled security assessments. Our commitment to proactive, customized penetration testing ensures your organization is safeguarded against evolving cyber threats while minimizing business disruption.
                </p>
                <Accordion accordionData={JvecApproachPenetrationAccordion} />
              </div>
              <div className='w-full md:w-1/2 mt-[40px] md:mt-0 max-w-[800px] h-[550px] '>
                <img src="/images/pentest/approach-img.jpg" alt="jvec approach" className='h-full w-full object-cover md:rounded-md' loading="lazy" />
              </div>
            </div> 
        </div>
    </section>
  )
}

export default JvecApproach