export const cybersecurityServices = [
    {
        title: "Expert-Led Assessments",
        description: "Our team of highly skilled cybersecurity experts brings years of experience to every assessment, ensuring comprehensive and accurate results."
    },
    {
        title: "Customized Approach",
        description: "We tailor our services to meet your specific needs and risk profile, providing a solution that fits your unique business requirements."
    },
    {
        title: "Cutting-Edge Technology",
        description: "We leverage the latest tools and techniques to identify even the most sophisticated vulnerabilities."
    },
    {
        title: "Proactive Security",
        description: "Our assessments help you identify and address vulnerabilities before they can be exploited by cybercriminals."
    },
    {
        title: "Actionable Insights",
        description: "Our detailed reports provide clear and actionable recommendations to help you prioritize and remediate vulnerabilities effectively."
    },
    {
        title: "Regulatory Compliance",
        description: "Ensure your organization complies with industry regulations and standards through our comprehensive assessments."
    },
    {
        title: "Risk Mitigation",
        description: "Minimize the risk of data breaches, financial loss, and reputational damage by addressing vulnerabilities proactively."
    },
    {
        title: "Enhanced Business Resilience",
        description: "Strengthen your organization's security posture and build resilience against cyber threats."
    },
    {
        title: "Peace of Mind",
        description: "Our comprehensive assessments provide the assurance that your systems and data are protected."
    },
    {
        title: "Partnership Approach",
        description: "We work closely with you to understand your business objectives and provide tailored solutions to meet your needs."
    }
];

export const securityAssessmentSteps = [
    {
      title: "Asset Discovery and Inventory",
      description: "Identification and documentation of all assets (e.g., servers, devices, applications) within the network to establish a clear security baseline."
    },
    {
      title: "Network Security Assessment",
      description: "Scanning and testing the security of the network infrastructure including firewalls, routers, switches, and endpoints to detect vulnerabilities and misconfigurations."
    },
    {
      title: "Applications Security Assessment",
      description: "Testing Web Applications, Mobile Applications, and APIs to identify vulnerabilities across the OWASP Top 10 and other extensive vulnerabilities including business logic."
    },
    {
      title: "Configuration Review",
      description: "Reviewing security configurations for servers, network devices, and applications to ensure settings adhere to industry best practices and compliance standards."
    },
    {
      title: "Patch Management Review",
      description: "Assessing the patching status of systems and applications to identify missing or outdated patches that may expose vulnerabilities."
    },
    {
      title: "Database Vulnerability Assessment",
      description: "Identifying vulnerabilities within databases by testing for risks like insecure permissions, weak encryption, and SQL injection points."
    },
    {
      title: "Wireless Network Security Assessment",
      description: "Testing wireless networks to identify vulnerabilities such as weak encryption, unauthorized access points, and insecure authentication protocols."
    },
    {
      title: "Credentialed and Non-Credentialed Scanning",
      description: "Running scans with both authenticated (credentialed) and non-authenticated (non-credentialed) access to detect vulnerabilities from both trusted and untrusted perspectives."
    },
    {
      title: "Security Control Validation",
      description: "Assessing the effectiveness of security controls, such as firewalls, IDS/IPS systems, and antivirus solutions, to ensure they are functioning correctly."
    },
    {
      title: "Compliance Assessment",
      description: "Evaluating system and network configurations against regulatory and industry standards (e.g., PCI DSS, NDPR, ISO 27001) to verify compliance."
    },
    {
      title: "Prioritization and Risk Scoring",
      description: "Ranking identified vulnerabilities by severity and potential business impact to guide remediation efforts based on risk."
    },
    {
      title: "Reporting and Documentation",
      description: "Providing a detailed report that includes identified vulnerabilities, risk levels, and remediation recommendations, along with executive summaries for stakeholder clarity."
    },
    {
      title: "Remediation Support and Consultation",
      description: "Assisting with the resolution of identified vulnerabilities, offering guidance on implementing patches, configuration changes, or other mitigation measures."
    },
    {
      title: "Continuous Vulnerability Monitoring",
      description: "Implementing ongoing vulnerability scanning and monitoring to detect new vulnerabilities in real-time as part of a continuous security strategy."
    },
    {
      title: "Remediation Verification and Re-Assessment",
      description: "Conducting follow-up assessments after remediation efforts to verify that vulnerabilities have been properly addressed and that no new issues have emerged."
    }
  ];

 export const proactiveDefenseFeatures = [
    {
      title: "Proactive Defense",
      description: "We prioritize Planning, Preparation, and Prevention, moving beyond reactionary troubleshooting to provide continuous, proactive security that strengthens your defense before an incident can occur."
    },
    {
      title: "Alignment with Business Goals",
      description: "JVEC ensures every technical recommendation aligns seamlessly with your business objectives, maximizing return on investment by focusing on solutions that drive value and support growth."
    },
    {
      title: "No-Surprise, Transparent Process",
      description: "Our 'No Surprise' policy ensures clarity and transparency from planning through execution, giving you confidence and control over your security strategy at every stage."
    },
    {
      title: "Operational Continuity",
      description: "With efficient and thorough Vulnerability Assessment, we help you maintain essential operations, minimizing any interruptions to your day-to-day activities while protecting your critical assets."
    },
    {
      title: "Personalized Consultative Experience",
      description: "We value personalized service, working closely with your team to ensure you’re never 'lost in the shuffle.' Our dedicated experts guide you through each step of the process, making complex security strategies accessible and actionable."
    }
  ];