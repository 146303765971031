import React from 'react'
import WhyChooseJvecAudit from './WhyChooseJvecAudit'

const AuditInfo = () => {
  return (
    <section className='py-20 overflow-hidden'>
        <div className='flex justify-center flex-col md:flex-row items-center gap-8 text-center md:text-left container lg:items-start'>
            <div className='space-y-6 max-w-[560px] md:w-1/2 text-[1rem] md:text-[1.15rem] leading-[1.5]'>
                <h1 className='text-[1.5rem] md:text-[2.3rem] lg:text-[3rem] font-semibold'>What is Technology Audit and Assurance?</h1>
                <div>
                    <p>
                    Technology Audit and Assurance is a specialized field that involves assessing the effectiveness and security of an organization's information technology systems and processes. It's designed to identify weaknesses, vulnerabilities, and compliance risks, and to provide recommendations for improvement.
                    </p>
                </div>
                <div className="space-y-3">
                    <h2 className='font-semibold'>Strengthen Your Digital Resilience with JVEC’s Technology Audit and Assurance Services</h2>
                    <p>
                        As a trusted leader in cybersecurity, JVEC Solutions is here to empower your organization with expert technology audit and assurance services. Our deep expertise and rigorous, compliance-driven approach are designed to secure, optimize, and align your technology with your business goals. With JVEC Solutions, you’re not just protecting assets—you’re elevating your operational efficiency, regulatory confidence, and stakeholder trust.
                    </p>
                </div>
            </div>

            <div className='md:h-[500px] rounded-3xl md:w-1/2'>
                <img src="/images/tech-audit-assurance/audit-Info.jpg" alt="audit-Info" className='h-full w-full object-cover bg-right rounded-[2rem]' loading="lazy"/>
            </div>

        </div>

        <WhyChooseJvecAudit />
    </section>
  )
}

export default AuditInfo