import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";

const LinkListNew = ({ items, baseClass, selectedStyle, urls }) => {
  const location = useLocation();
  return (
    <ul className={`flex flex-col gap-2`}>
      {items.map((item, index) => (
        <li
          key={index}
          className={`text-white text-sm pb-1 font-bold ${baseClass} font-['Noto Sans'] leading-tight tracking-tight`}
          style={
            location.pathname === (urls[index] || `/services/${item.toLowerCase().split(' ')[0]}`)
              ? selectedStyle
              : null
          }
        >
          <Link to={urls[index] || `/services/${item.toLowerCase().split(' ')[0]}`}>
            {item}
          </Link>
        </li>
      ))}
    </ul>
  );
};


const iconStyle = {
  marginLeft: "4px",
  fontSize: "12px",
  fontWeight: "normal",
};

const selectedStyle = {
  color: "#4169E1",
  borderBottom: "3px solid",
  paddingBottom: "4px",
  fontWeight: "700",
  fontSize: "14px",
};

const NavBar = () => {
  const location = useLocation();

  const servicesRef = useRef(null);
  const aboutUsRef = useRef(null);
  const moreRef = useRef(null);

  const [isServicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const [isAboutUsDropdownOpen, setAboutUsDropdownOpen] = useState(false);
  const [isMoreDropdownOpen, setMoreDropdownOpen] = useState(false);

  const toggleServicesDropdown = () => {
    setServicesDropdownOpen(!isServicesDropdownOpen);
    setAboutUsDropdownOpen(false);
    setMoreDropdownOpen(false);
  };

  const toggleAboutUsDropdown = () => {
    setAboutUsDropdownOpen(!isAboutUsDropdownOpen);
    setServicesDropdownOpen(false);
    setMoreDropdownOpen(false);
  };

  const toggleMoreDropdown = () => {
    setMoreDropdownOpen(!isMoreDropdownOpen);
    setServicesDropdownOpen(false);
    setAboutUsDropdownOpen(false);
  };

  const closeAllDropdowns = () => {
    setServicesDropdownOpen(false);
    setAboutUsDropdownOpen(false);
    setMoreDropdownOpen(false);
  };

  const openDropdownWithDelay = (toggleFunction) => {
    toggleFunction();
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      const isOutsideServices =
        !servicesRef.current || !servicesRef.current.contains(event.target);
      const isOutsideAboutUs =
        !aboutUsRef.current || !aboutUsRef.current.contains(event.target);
      const isOutsideMore =
        !moreRef.current || !moreRef.current.contains(event.target);

      if (isOutsideServices && isOutsideAboutUs && isOutsideMore) {
        closeAllDropdowns();
      }
    };

    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  return (
    <nav className="bg-opacity-0 p-4 hidden lg:block">
      <div className="container mx-auto flex space-x-4 justify-center relative">
        {/* Home */}
        <Link
          to="/"
          className={`text-white mr-2 linkButton ${
            location.pathname === "/" ? "selected linkButton" : ""
          }`}
          style={location.pathname === "/" ? selectedStyle : {}}
          onClick={closeAllDropdowns}
        >
          Home
        </Link>

        {/* Services Dropdown */}
        <div
          className="relative font-noto"
          ref={servicesRef}
          onMouseEnter={() => openDropdownWithDelay(toggleServicesDropdown)}
          onMouseLeave={() => setServicesDropdownOpen(false)}
        >
          <button
            className={`text-white focus:outline-none flex items-center linkButton ${
              location.pathname.startsWith("/service") ? "selected " : ""
            }`}
          >
            <Link to={"services"} className={`text-white mr-2 linkButton ${
            location.pathname === "/" ? "selected linkButton" : ""
          }`}> Services</Link>
            {isServicesDropdownOpen ? (
              <FaChevronUp style={iconStyle} />
            ) : (
              <FaChevronDown
                onClick={toggleServicesDropdown}
                style={iconStyle}
              />
            )}
          </button>
          {isServicesDropdownOpen && (
            <div className="absolute pt-2">
              <div className="h-fit w-[44rem] bg-[#131313] rounded-md py-10 px-10 ml-1">
                <p className="text-[#86868B] text-sm font-normal mb-3">
                  Explore Services
                </p>
                <div className="links-holder flex w-full justify-between items-start -left-32">
                  <LinkListNew
                    baseClass="hover:text-[#FFA000] duration-75"
                    selectedStyle={selectedStyle}
                    items={[
                      "Website Development",
                      "Mobile App Development",
                      "Software Development",
                      "Digital Marketing",
                      "Product Design",
                      "Product Management",
                      "Predictive Analysis",
                    ]}
                    urls={[
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    "/services/product_management",
                    undefined,
                  ]}
                  />
                  <LinkListNew
                    baseClass="hover:text-[#FFA000] duration-75"
                    selectedStyle={selectedStyle}
                    items={[
                      "Graphics Design",
                      "Motion Design / Video Editing",
                      "Consulting",
                      "Corporate Training",
                      "Data Consultancy",
                      "Project Management",
                      "Technology Audit/Assurance",
                    ]}
                    urls={[
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    "/services/data_consultancy",
                    "/services/project_management",
                    "/tech-audit",
                  ]}
                  />

                  <LinkListNew
                    baseClass="hover:text-[#FFA000] duration-75"
                    selectedStyle={selectedStyle}
                    items={[
                      "Compliance Consulting",
                      "Vulnerability Assessment",
                      "Penetration Testing",
                      "Cybersecurity Awareness Training",
                      "Cybersecurity Advisory/Consulting",

                    ]}
                    urls={[
                      "/compliance-consulting",
                      "/vulnerability-assessment",
                      "/pentest",
                      "/cybersecurity-awareness",
                      "/cybersecurity-consulting",
                    ]}
                  />
                </div>
              </div>
            </div>
          )}
          {/* {isServicesDropdownOpen && (
            <div className="absolute bg-gray-800 mt-5 pt-2 LinkListContainer">
              <LinkList
                items={[
                  "Website Development",
                  "Mobile App Development",
                  "Software Development",
                  "Digital Marketing",
                  "Product Design",
                  "Graphics Design",
                  "Motion Design / Video Editing",
                  "Consulting",
                  "Corporate Training",
                ]}
                baseClass="linkButton"
                selectedStyle={selectedStyle}
              />
            </div>
          )} */}
        </div>
        <Link
          to="/industries"
          className={`text-white mr-2 linkButton ${
            location.pathname === "/industries" ? "selected" : ""
          }`}
          style={location.pathname === "/industries" ? selectedStyle : {}}
          onClick={closeAllDropdowns}
        >
          Industries
        </Link>
        {/* About Us Dropdown */}
        <div
          className="relative mr-2"
          ref={aboutUsRef}
          onMouseEnter={() => openDropdownWithDelay(toggleAboutUsDropdown)}
          onMouseLeave={() => setAboutUsDropdownOpen(false)}
        >
          <button
            onClick={toggleAboutUsDropdown}
            className={`text-white focus:outline-none flex linkButton items-center ${
              location.pathname.startsWith("/team") ? "selected" : ""
            }`}
          >
            About
            {isAboutUsDropdownOpen ? (
              <FaChevronUp style={iconStyle} />
            ) : (
              <FaChevronDown style={iconStyle} />
            )}
          </button>
          {isAboutUsDropdownOpen && (
            <div className="absolute pt-2">
              <div className="h-fit w-[30rem] bg-[#131313] rounded-md py-10 px-10 ml-1">
                <p className="text-[#86868B] text-sm font-normal mb-3">
                  Explore About
                </p>
                <div className="links-holder flex w-full justify-between items-center -left-32">
                  <Link
                    to="/about"
                    className={`text-white text-sm font-bold hover:text-[#FFA000] duration-75 ${
                      location.pathname === "/about"
                        ? "text-primary-500"
                        : ""
                    }`}
                    style={
                      location.pathname === "/about" ? selectedStyle : {}
                    }
                  >
                    About Us
                  </Link>
                  <Link
                    to="/internship"
                    className={`text-white text-sm font-bold hover:text-[#FFA000] duration-75  ${
                      location.pathname === "/internship"
                        ? "text-primary-500"
                        : ""
                    }`}
                    style={
                      location.pathname === "/internship" ? selectedStyle : {}
                    }
                  >
                    Internship
                  </Link>
                  <Link
                    to="/openings"
                    className={`text-white text-sm font-bold hover:text-[#FFA000] duration-75  ${
                      location.pathname === "/openings"
                        ? "text-primary-500"
                        : ""
                    }`}
                    style={
                      location.pathname === "/openings" ? selectedStyle : {}
                    }
                  >
                    Openings
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Training */}
        {/* <Link
          to="/training"
          className={`text-white mr-2 linkButton ${
            location.pathname === "/training" ? "selected" : ""
          }`}
          style={location.pathname === "/training" ? selectedStyle : {}}
          onClick={closeAllDropdowns}
        >
          Training
        </Link>  <-- Uncomment this and delete the one below after testing... */}
        <Link
          to="/training"
          className={`text-white mr-2 linkButton ${
            location.pathname === "/new-training" ? "selected" : ""
          }`}
          style={location.pathname === "/new-training" ? selectedStyle : {}}
          onClick={closeAllDropdowns}
        >
          Training
        </Link>

        {/* More Dropdown */}
        <div
          className="relative mr-2"
          ref={moreRef}
          onMouseEnter={() => openDropdownWithDelay(toggleMoreDropdown)}
          onMouseLeave={() => setMoreDropdownOpen(false)}
        >
          <button
            onClick={toggleMoreDropdown}
            className={`text-white focus:outline-none linkButton flex items-center ${
              location.pathname.startsWith("/more") ? "selected" : ""
            }`}
          >
            More
            {isMoreDropdownOpen ? (
              <FaChevronUp style={iconStyle} />
            ) : (
              <FaChevronDown style={iconStyle} />
            )}
          </button>

          {isMoreDropdownOpen && (
            <div className="absolute pt-2">
              <div className="h-fit w-[20rem] bg-[#131313] rounded-md py-8 px-10 ml-1">
                <p className="text-[#86868B] text-sm font-normal mb-2">
                  Explore More
                </p>
                <div className="links-holder flex w-full justify-between items-center -left-32">
                  <Link
                    to="/blogs"
                    className={`text-white text-sm font-bold hover:text-[#FFA000] duration-75 ${
                      location.pathname === "/blogs" ? "selected" : ""
                    }`}
                    style={location.pathname === "/blogs" ? selectedStyle : {}}
                  >
                    Blog
                  </Link>
                  <Link
                    to="/portfolio"
                    className={`text-white text-sm font-bold hover:text-[#FFA000] duration-75  ${
                      location.pathname === "/portfolio" ? "selected" : ""
                    }`}
                style={location.pathname === "/portfolio" ? selectedStyle : {}}
              >
                Portfolio
                  </Link>
                  <Link
                    to="/testimonials"
                    className={`text-white text-sm font-bold hover:text-[#FFA000] duration-75  ${
                      location.pathname === "/testimonials" ? "selected" : ""
                    }`}
                style={location.pathname === "/testimonials" ? selectedStyle : {}}
              >
                Testimonials
                  </Link>
            </div>
        </div>
          </div>
          )}
        </div>
      </div>
    </nav>
  );
};


export default NavBar;
