import React from 'react'
import Accordion from '../../../components/accordion/accordion';
const securityFeatures = [
    {
      title: "Expert-Driven Resilience",
      description: "Build a security strategy backed by JVEC’s decades of experience to withstand complex threats."
    },
    {
      title: "Operational Confidence",
      description: "Empower your organization to operate securely and confidently with a robust, transparent security framework."
    },
    {
      title: "Compliance Assurance",
      description: "Stay aligned with regulatory requirements effortlessly through JVEC’s compliance expertise."
    },
    {
      title: "Tailored, ROI-Focused Solutions",
      description: "See immediate value and long-term savings through cybersecurity strategies specifically designed to meet your business goals."
    }
  ];

const JvecAdvantages = () => {
  return (
    <section className='py-20'>
        <div className='overflow-hidden container'>
            <h1 className='text-[1.5rem] md:text-[2.3rem] lg:text-[3rem] font-semibold mb-8 text-center md:text-left lg:max-w-[700px]'>The JVEC Advantage</h1>

            <div className='md:flex justify-start gap-3 mb-[40px]'>
              <div className='space-y-6 md:w-1/2'>
                <p className='text-center md:text-left text-[1rem] md:text-[1.15rem] leading-[1.5]'>
                  As a trusted partner across industries, JVEC Solutions leverages over 45 years of expertise to provide tailored cybersecurity solutions for Financial Services, Information Technology, Healthcare, Retail, Manufacturing, and more. Our industry-specific approach ensures your organization remains secure, compliant, and resilient in an ever-changing threat landscape.
                </p>
                <Accordion accordionData={securityFeatures} />
              </div>
              <div className='w-full mt-[40px] md:mt-0 max-w-[800px] h-[550px] md:w-1/2'>
                <img src="/images/industry/jvec-advantage.jpg" alt="jvec Info" className='h-full w-full object-cover bg-right rounded-[2rem]' loading="lazy"/>
              </div>
            </div>
        </div>
    </section>
  )
}

export default JvecAdvantages